<template>
    <div role="group" aria-label="Audio length selection">
        <div class="container-buttons">
            <div class="icon">
                <img src="/icons/waveform.svg" width="20" height="20" alt="waveform icon" />
            </div>

            <div v-for="option in options"
                 :class="['box', option.status == 'SELECTED' ? 'selected' : '', option.status == 'DESELECTED' ? 'deselected' : '']"
                 role="button" 
                 @click="toggleSelect(option)">
                <span class="text">{{ option.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import TagSelectButton from './TagSelectButton.vue'

    export default {
        name: 'LengthSelectContainer',
        emits: ['statusesChanged'],
        components: {
            TagSelectButton
        },  
        props: {
            deselectedLengthOptionIds: {
                default: [],
                type: Array
            },
        },
        data() {
            return {
                options: [
                    { id: 1, name: "0-5min", status: "SELECTED" },
                    { id: 2, name: "5-10min", status: "SELECTED" },
                    { id: 3, name: "10-20min", status: "SELECTED" },
                    { id: 4, name: "20-40min", status: "SELECTED" },
                    { id: 5, name: "40-60min", status: "SELECTED" },
                    { id: 6, name: "60min+", status: "SELECTED" },
                ],
            }
        },
        methods: {
            toggleSelect(option) {
                if (option.status == "SELECTED") {
                    option.status = "DESELECTED"
                } else {
                    option.status = "SELECTED"
                }

                this.$emit("statusesChanged", this.options)
            }
        },
        mounted() {
            for (let option of this.options) {
                if (this.deselectedLengthOptionIds.includes(option.id)) {
                    option.status = "DESELECTED"
                }
            }
        }
    }
</script>

<style scoped>
    .container-buttons {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        padding: 0;
        gap: 0.5rem;
    }

    .category-row {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 0.3rem;
    }

    .icon {
        color: white;
        padding-left: 0.5rem;
        display: flex;
        align-items: center;
    }

    .box {
        border-radius: 6px;
        border: 1px solid #46424F;
        padding: 5px 10px 5px 10px;
        cursor: pointer;
        user-select: none;
        transition: background linear .2s;
    }

    .text {
        font-size: 16px;
        line-height: 24px;
        color: #ADADCB;
        transition: color linear .2s;
    }

    .selected {
        border-color: var(--color-green);
        background: var(--color-green-transparent);
    }

    .deselected {
        border-color: var(--color-red);
        background: var(--color-red-transparent);
        color: #FFFFFF;
    }

        .deselected .text {
            color: var(--color-red);
        }  
</style>