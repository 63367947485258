<template>
    <div class="search-container" :class="[showPreferenceButton ? '' : 'one-btn']" role="search">
        <div class="search-bar">
            <div class="search-icon">
                <img src="/icons/search-normal.svg" alt="search icon"/>
            </div>
            <input class="search-box form-control" type="text" aria-label="Search box" placeholder="What do you like?" v-model="query" @keydown.enter="search" />
            <button class="btn btn-primary search-button" @click="search()" aria-label="Search">
                Search
            </button>
        </div>
        <div class="button-container">
            <button v-if="showPreferenceButton" class="btn btn-secondary preferences-btn" @click="togglePreferences()" aria-label="Select preferences" title="Preferences" ref="preferences">
                <div class="click-me">try filters <span ref="smiley">:)</span></div>
                <img src="/icons/star.svg" alt="star icon"/>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SearchBox',
        emits: ['search', 'togglePreferences'],
        props: {
            showPreferenceButton: {
                default: true,
                type: Boolean
            }
        },
        data() {
            return {
                query: ""
            }
        },
        methods: {
            search() {
                this.$emit("search", this.query)
            },
            togglePreferences() {
                this.$emit("togglePreferences")
            }
        },
        mounted() {
            let preferences = this.$refs.preferences
            let smiley = this.$refs.smiley

            if (!preferences || !smiley)
                return

            preferences.addEventListener('mouseenter', function () {
                smiley.innerHTML = ':D'
            })

            preferences.addEventListener('mouseleave', function () {
                smiley.innerHTML = ':)'                
            })
        }
    }
</script>

<style scoped>
    .search-container {
        display: flex;
        flex-flow: row;
        justify-content: center;
        gap: 0.5rem;
        flex-grow: 1;
    }

    .button-container {
        display: flex;
        flex-flow: row-reverse;
        justify-content: center;
        gap: 0.5rem;
    }

    .search-box {
        padding: 0.5rem 1rem;
        background: var(--secondary-background) !important;
        border: 0px;
        border-radius: 0;
        color: white;
    }

        .search-box:focus {
            box-shadow: transparent 0 0 inset;
            color: white;
        }

    .preferences-btn {
        width: 57px;
    }

    .preferences-btn img {
        -moz-animation-duration: 5s;
        -o-animation-duration: 5s;
        -webkit-animation-duration: 5s;
        animation-duration: 5s;
        -moz-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -moz-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    .search-bar {
        display: flex;
        flex-direction: row;
        width: 100%;
    }


    .search-icon {
        background: var(--secondary-background);
        border-radius: 15px 0 0 15px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding-left: 15px;
    }

    .preferences-btn:hover .click-me {
        transform: translate3d(15px, -30px, 0px) rotate(28deg);
    }

    @media only screen and (max-width: 40rem) {
        .preferences-btn:hover .click-me {
            transform: translate3d(-15px, -35px, 0px) rotate(0deg);
        }
    }

    .click-me {
        position: absolute;
        font-size: 11px;
        color: white;
        transform: translate3d(0px, -10px, 0px) rotate(28deg);
        pointer-events: none;
        transition: transform ease-in-out var(--def-transition-duration);
    }

    .search-button {
        width: 130px;
        border-radius: 0 15px 15px 0;
        display: flex;
        justify-content: center;
        color: white !important;
        background: linear-gradient(270deg, #BA324F, #c91eae, #5d1ec9);
        background-size: 2000% 2000%;
        animation: gradientAnimation 25s linear infinite;
    }

    @keyframes gradientAnimation {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }
</style>