import { createStore } from 'vuex'
import axios from 'axios'
import endpoints from './api/endpoints.js'
import { getCookie, setCookie, removeCookie } from './utils.js'

const store = createStore({
    state() {
        return {
            userData: null,
            userHistory: {
                upvotes: [],
                listens: []
            },
            layout: {
                isSidebarOpen: false
            },
            searchPosition: {
                query: null,
                searchContents: null,
                posY: null,
                listenerAdded: false
            },
            tagSave: {
                tags: null,
                selectedTagIds: [],
                deselectedTagIds: [],
                deselectedLengthOptionIds: []
            },
            player: {
                currentAudio: {
                    audio: null,
                    file: null
                },
                isPlaying: false,
                isAudioLoaded: false,
                isListenSent: false,
                audioVolume: 1,
                setAudio(audio) {
                    this.currentAudio.audio = audio
                    this.isListenSent = false

                    if ('mediaSession' in navigator) {
                        const baseUrl = window.location.origin

                        navigator.mediaSession.metadata = new MediaMetadata({
                            title: audio.name,
                            artist: audio.profile.name,
                            artwork: [
                                { src: `${baseUrl}/images/lock-96.png`, sizes: '96x96', type: 'image/png' },
                                { src: `${baseUrl}/images/lock-128.png`, sizes: '128x128', type: 'image/png' },
                                { src: `${baseUrl}/images/lock-192.png`, sizes: '192x192', type: 'image/png' }
                            ]
                        })
                    }
                },
                setFile(file) {
                    this.currentAudio.file = file
                },
                clearAudio() {
                    this.currentAudio.audio = null
                    this.clearAudio.file = null
                    this.isPlaying = false
                    this.isAudioLoaded = false
                }
            }
        }
    },
    getters: {
        userData(state) {
            return state.userData
        },
        userHistory(state) {
            return state.userHistory 
        },
        userProfileId(state) {
            return state.userData.userProfileId
        },
        layout(state) {
            return state.layout
        },
        searchPosition(state) {
            return state.searchPosition
        },
        tagSave(state) {
            return state.tagSave
        },
        player(state) {
            return state.player
        }
    },
    mutations: {
        setTokens(state, tokens) {
            if (tokens == null) {
                removeCookie("tokens")
            }
            else {
                setCookie("tokens", tokens)
            }
        },
        setUserData(state, userData) {
            state.userData = userData
            if (userData == null) {
                removeCookie("user_data")
            }
            else {
                setCookie("user_data", state.userData)
            }
        },
        setUser(state, user) {
            if (state.userData) {
                state.userData.user = user
            }
        },
        setUserHistory(state, userHistory) {
            state.userHistory = userHistory
        },
        setUserProfileId(state, userProfileId) {
            if (state.userData) {
                state.userData.userProfileId = userProfileId
                setCookie("user_data", state.userData)
            }
        },
        setHandle(state, handle) {
            if (state.userData) {
                state.userData.handle = handle
                setCookie("user_data", state.userData)
            }
        },
        setTagSave(state, tagSave) {
            state.tagSave = tagSave

            if (tagSave == null) {
                state.tagSave = {
                    tags: null,
                    selectedTagIds: [],
                    deselectedTagIds: []
                }
                removeCookie("tag_save")
            }
            else {
                setCookie("tag_save", state.tagSave)
            }
        }
    },
    actions: {
        async login(context, { username, password }) {
            try {
                const response = await axios.post(endpoints.UserAccess.Authorization.Login(), {
                    username: username,
                    password: password
                })

                const data = response.data
                if (data.isAuthenticated) {
                    const userData = {
                        user: data.user,
                    }

                    const tokens = {
                        token: data.token,
                        refreshToken: data.refreshToken,
                        expiration: data.expiration
                    }

                    context.commit('setUserData', userData)
                    context.commit('setTokens', tokens)
                }

                return data
            } catch (error) { }
            return false    
        },
        async loginWithState(context, { thirdParty, state }) {
            try {
                const response = await axios.post(endpoints.UserAccess.Authorization.LoginWithState(thirdParty, state))

                const data = response.data
                if (data.isAuthenticated) {
                    const userData = {
                        user: data.user,
                    }

                    const tokens = {
                        token: data.token,
                        refreshToken: data.refreshToken,
                        expiration: data.expiration
                    }

                    context.commit('setUserData', userData)
                    context.commit('setTokens', tokens)
                }

                return data
            } catch (error) { }
            return false
        },
        async refreshToken(context) {
            try {
                const tokens = getCookie("tokens")

                const response = await axios.post(endpoints.UserAccess.Authorization.RefreshToken(), {
                    token: tokens.token,
                    refreshToken: tokens.refreshToken
                })
                const data = response.data

                if (data.isRefreshed) {
                    tokens.token = data.token
                    tokens.refreshToken = data.refreshToken
                    tokens.expiration = new Date(data.expiration)

                    context.commit('setTokens', tokens)

                    return true
                }

                console.log("FAILURE REFRESHING", tokens.refreshToken, "RESPONSE", data)

            } catch (error) { }

            console.log("NORMALLY WOULD NOW LOG OUT")
            //await context.dispatch('logout')

            return false
        },
        async logout(context) {
            context.commit('setTokens', null)
            context.commit('setUserData', null)
            context.commit('setTagSave', null)
        }
    }
})

export default store