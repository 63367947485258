<template>
    <h4 class="title">Listens By Time</h4>
    <div class="card card-dark">
        <div class="card-body">
            <label class="sum">
                <fai :icon="['fas', 'clock']"></fai>
                {{ totalListens }} listens during this time

            <span v-if="totalListensThirdParty > 0 && totalListensOnPlatform > 0">
                <img src="/icons/lovely-orange.svg" width="24" height="24" alt="AudioLove logo" />

                {{ totalListensOnPlatform }} on AudioLove and {{ totalListensThirdParty }} on third parties
            </span>
            </label>
            <div class="time-select">
                <select v-model="selectedTime" class="form-select form-select-pink time-select" aria-label="Time selection" @change="loadData()">
                    <option value="1">Last week</option>
                    <option value="2">Last month</option>
                    <option value="3">Last three months</option>
                    <option value="4">Last six months</option>
                    <option value="5">Last year</option>
                    <option value="6">All time</option>
                </select>
                <button v-if="isAdminChart" class="btn-option" @click="toggleChart()">
                    <fai :icon="['fas', 'layer-group']" :class="[activeChart == 2 ? 'color-primary' : '']"></fai>
                </button>
            </div>
            <div class="chart-container">
                <div v-if="!chartData" class="dot-flashing"></div>
                <div v-else-if="notEnoughData">Not enough data. Try again tomorrow.</div>
                <canvas v-show="chartData && activeChart == 1 && !notEnoughData" ref="chart" />
                <canvas v-show="chartData && activeChart == 2 && !notEnoughData" ref="chartTwo" />
            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { chartDataFromDatasets, getTomorrowDate } from '../utils.js'
    import Chart from 'chart.js/auto'

    export default {
        name: 'TotalAudioListensByTimeChart',
        props: {
            isAdminChart: {
                default: false,
                type: Boolean
            },
            audioId: {
                default: null,
                type: String
            },
            userId: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                selectedTime: "2",
                chartData: null,
                notEnoughData: false,
                totalListens: 0,
                totalListensOnPlatform: 0,
                totalListensThirdParty: 0,
                activeChart: 1,
                datasetLabels: ["AudioLove", "Third Party"]
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData == null) {
                    return null
                }

                return this.$store.getters.userData.user
            }
        },
        methods: {
            toggleChart() {
                this.activeChart = this.activeChart == 1 ? 2 : 1
            },
            async loadData() {
                const tomorrowDate = getTomorrowDate()
                const fromDate = this.getFromDate()

                const result = await requests.Statistics.Charts.TotalAudioListensByTime(
                    this.audioId,
                    this.isAdminChart
                        ? null
                        : this.userId,
                    fromDate, tomorrowDate)

                if (!result.isSuccess) {
                    return
                }

                this.totalListensOnPlatform = result.datasets[0].reduce((accumulator, currentData) => {
                    return accumulator + currentData.value;
                }, 0);
                this.totalListensThirdParty = result.datasets[1].reduce((accumulator, currentData) => {
                    return accumulator + currentData.value;
                }, 0);

                this.totalListens = this.totalListensOnPlatform + this.totalListensThirdParty

                this.chartData = chartDataFromDatasets(result.datasets, ["#A688FA", "#F59460"])
                this.notEnoughData = this.chartData.labels.length < 3

                if (!this.$refs.chart || !this.$refs.chartTwo) {
                    return
                }

                for (let i = 0; i < this.chartData.datasets.length; i++) {
                    this.chartData.datasets[i].label = this.datasetLabels[i]
                }

                const filledChartData = JSON.parse(JSON.stringify(this.chartData))
                filledChartData.datasets.forEach(dataset => {
                    dataset.fill = true
                })

                if (this.chart && this.chartTwo) {
                    this.chart.data = this.chartData
                    this.chartTwo.data = filledChartData

                    this.chart.update()
                    this.chartTwo.update()
                    return
                }

                const ctx = this.$refs.chart.getContext('2d');
                const ctxTwo = this.$refs.chartTwo.getContext('2d');

                const config = {
                    type: 'line',
                    data: this.chartData,
                    options: {
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                callbacks: {
                                    label: (tooltipItem) => {
                                        const value = tooltipItem.raw

                                        if (this.totalListensThirdParty == 0 || this.totalListensOnPlatform == 0) {
                                            return `${value}`
                                        }

                                        const datasetIndex = tooltipItem.datasetIndex
                                        return `${tooltipItem.dataset.label}: ${value}`
                                    },
                                    footer: (tooltipItems) => {
                                        if (this.totalListensThirdParty == 0 || this.totalListensOnPlatform == 0) {
                                            return null
                                        }

                                        const sum = tooltipItems.reduce((acc, item) => acc + item.raw, 0)
                                        return `Total: ${sum}`
                                    }
                                }
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        scales: {
                            y: {
                                min: 0,
                            }
                        }
                    },
                }
                
                const configTwo = {
                    type: 'line',
                    data: filledChartData,
                    options: {
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                callbacks: {
                                    label: (tooltipItem) => {
                                        const value = tooltipItem.raw

                                        if (this.totalListensThirdParty == 0 || this.totalListensOnPlatform == 0) {
                                            return value
                                        }

                                        const datasetIndex = tooltipItem.datasetIndex
                                        return ` ${tooltipItem.dataset.label}: ${value}`
                                    },
                                    footer: (tooltipItems) => {
                                        if (this.totalListensThirdParty == 0 || this.totalListensOnPlatform == 0) {
                                            return null
                                        }

                                        const sum = tooltipItems.reduce((acc, item) => acc + item.raw, 0)
                                        return `Total: ${sum}`
                                    }
                                }
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        scales: {
                            y: {
                                min: 0,
                                stacked: true
                            }
                        }
                    },
                }

                this.chart = new Chart(ctx, config);
                this.chartTwo = new Chart(ctxTwo, configTwo);
            },
            getFromDate() {
                const offset = (new Date()).getTimezoneOffset()

                if (this.selectedTime == "1") {
                    const weekBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    weekBeforeDate.setDate(weekBeforeDate.getDate() - 7)
                    return weekBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "2") {
                    const monthBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    monthBeforeDate.setDate(monthBeforeDate.getDate() - 31)
                    return monthBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "3") {
                    const threeMonthsBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    threeMonthsBeforeDate.setDate(threeMonthsBeforeDate.getDate() - 93)
                    return threeMonthsBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "4") {
                    const sixMonthsBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    sixMonthsBeforeDate.setDate(sixMonthsBeforeDate.getDate() - 186)
                    return sixMonthsBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "5") {
                    const yearBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    yearBeforeDate.setDate(yearBeforeDate.getDate() - 365)
                    return yearBeforeDate.toISOString().split('T')[0]
                } else {
                    const allTimeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    allTimeDate.setDate(allTimeDate.getDate() - 10000)
                    return allTimeDate.toISOString().split('T')[0]
                }
            }
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.loadData()
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 32px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }

    .time-select {
        width: 12rem;
        margin-bottom: 2rem;
        display: flex;
        gap: 8px;
    }

    .btn-option {
        border-radius: 6px;
        border: 2px solid var(--primary-color);
        height: 40px;
        padding: 7px;
    }

    .chart-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10rem;
    }

    @media only screen and (min-width: 70rem) {
        .chart-container {
            height: 20rem;
        }
    }

    .sum {
        color: var(--color-orange);
        padding-bottom: 16px;
        font-size: 16px;
    }
</style>